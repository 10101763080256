// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}serverEstates/listEstatesOrder`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}serverEstates/exportEstatesOrder`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 获取详情
const getEstatesOrderFullURL = `${API_CONFIG.butlerBaseURL}estates/getEstatesOrderFull`
// 重试销账
const retryChargeOffURL = `${API_CONFIG.butlerBaseURL}serverEstates/retryChargeOff`

export {
  getListURL,
  exportListURL,
  batchURL,
  getEstatesOrderFullURL,
  retryChargeOffURL
}
