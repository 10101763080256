import { generateMapByOpts } from 'common/utils'

// 缴费途径
const optSourceOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '业主端App',
    value: 1
  },
  {
    text: '物管端App',
    value: 5
  },
  {
    text: '支付宝小程序',
    value: 6
  },
  {
    text: '微信小程序',
    value: 7
  }
]

const optSourceMap = generateMapByOpts(optSourceOps)

// 付款方式

const moneyTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '支付宝',
    value: 1
  },
  {
    text: '微信',
    value: 12
  },
  {
    text: '余额支付',
    value: 5
  },
  {
    text: '零额支付',
    value: 99
  },
  {
    text: '线下支付',
    value: 0
  }
]

const moneyTypeMap = generateMapByOpts(moneyTypeOps)

// 订单状态
const orderStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '已销账',
    value: 3
  },
  {
    text: '销账失败',
    value: 4
  },
  {
    text: '待销账',
    value: 2
  }
]

// const orderStatusMap = generateMapByOpts(orderStatusOps)
const orderStatusMap = {
  0: '未知状态',
  1: '已下单,支付中',
  2: '已支付,销账中',
  3: '已销账,处理完成',
  4: '销账失败',
  5: '关闭',
  6: '销账再次失败'
}

export {
  optSourceOps,
  optSourceMap,
  moneyTypeOps,
  moneyTypeMap,
  orderStatusOps,
  orderStatusMap
}
