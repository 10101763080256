var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderPayOnlineForm-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: { queryUrl: _vm.queryUrl, footerShow: false },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-block",
            { attrs: { title: "业主信息" } },
            [
              _c("col2-item", {
                attrs: { label: "业主姓名", text: _vm.orderInfo.ownerName },
              }),
              _c("col2-item", {
                attrs: {
                  label: "业主电话",
                  text: _vm.orderInfo.ownerMobileNum,
                },
              }),
              _c("col2-item", {
                attrs: { label: "业主房号", text: _vm.orderInfo.ownerRoomName },
              }),
              _c("col2-item", {
                attrs: {
                  label: "所属公司",
                  text: _vm.orderInfo.ownerRegionName,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "所属项目",
                  text: _vm.orderInfo.ownerCommunityName,
                },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "付款人信息" } },
            [
              _c("col2-item", {
                attrs: { label: "付款人姓名", text: _vm.orderInfo.payUserName },
              }),
              _c("col2-item", {
                attrs: {
                  label: "付款人电话",
                  text: _vm.orderInfo.payUserPhone,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "联系地址",
                  text: _vm.orderInfo.payUserAddress,
                },
              }),
              _c("col2-item", {
                attrs: { label: "缴费途径", text: _vm.orderInfo.optSourceName },
              }),
              _c("col2-item", {
                attrs: {
                  label: "付款方式",
                  text: _vm.moneyTypeMap[_vm.orderInfo.moneyType],
                },
              }),
              _c("col2-item", { attrs: { label: "实付金额" } }, [
                _c("span", [
                  _vm._v("￥" + _vm._s(_vm.orderInfo.orderMoneyReal)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "业务订单信息" } },
            [
              _c("col2-item", {
                attrs: { label: "付款单号", text: _vm.orderInfo.serialNumber },
              }),
              _c("col2-item", {
                attrs: { label: "订单类别", text: "物业缴费" },
              }),
              _c("col2-item", {
                attrs: {
                  label: "订单金额",
                  text: `￥${_vm.orderInfo.orderMoneyTotal || ""}`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "实收金额(元)",
                  text: `￥${_vm.orderInfo.orderMoneyReal || ""}`,
                },
              }),
              _c("col2-item", {
                attrs: { label: "下单时间", text: _vm.orderInfo.referTime },
              }),
              _c("col2-item", {
                attrs: { label: "支付完成时间", text: _vm.orderInfo.paidTime },
              }),
              _c("col2-item", {
                attrs: {
                  label: "订单完成时间",
                  text: _vm.orderInfo.chargeoffTime,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "订单状态",
                  text: _vm.orderInfo.orderStatusName,
                },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "订单明细" } },
            [
              _c("table-panel", {
                attrs: {
                  headers: _vm.orderHeaders,
                  tableData: _vm.orderList,
                  hasOperateColumn: false,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }