<template>
  <div class="orderPayOnlineForm-container">
    <form-panel
      ref="form"
      :queryUrl="queryUrl"
      @update="update"
      :footerShow="false"
    >
      <col2-block title="业主信息">
        <col2-item label="业主姓名" :text="orderInfo.ownerName" />
        <col2-item label="业主电话" :text="orderInfo.ownerMobileNum" />
        <col2-item label="业主房号" :text="orderInfo.ownerRoomName" />
        <col2-item label="所属公司" :text="orderInfo.ownerRegionName" />
        <col2-item label="所属项目" :text="orderInfo.ownerCommunityName" />
      </col2-block>
      <col2-block title="付款人信息">
        <col2-item label="付款人姓名" :text="orderInfo.payUserName" />
        <col2-item label="付款人电话" :text="orderInfo.payUserPhone" />
        <col2-item label="联系地址" :text="orderInfo.payUserAddress" />
        <col2-item label="缴费途径" :text="orderInfo.optSourceName" />
        <col2-item label="付款方式" :text="moneyTypeMap[orderInfo.moneyType]" />
        <col2-item label="实付金额">
          <span>￥{{orderInfo.orderMoneyReal}}</span>
        </col2-item>
      </col2-block>
      <col2-block title="业务订单信息">
        <col2-item label="付款单号" :text="orderInfo.serialNumber" />
        <col2-item label="订单类别" text="物业缴费" />
        <col2-item label="订单金额" :text="`￥${orderInfo.orderMoneyTotal || ''}`" />
        <col2-item label="实收金额(元)" :text="`￥${orderInfo.orderMoneyReal || ''}`" />
        <col2-item label="下单时间" :text="orderInfo.referTime" />
        <col2-item label="支付完成时间" :text="orderInfo.paidTime" />
        <col2-item label="订单完成时间" :text="orderInfo.chargeoffTime" />
        <col2-item label="订单状态" :text="orderInfo.orderStatusName" />
      </col2-block>
      <col2-block title="订单明细">
        <table-panel
          :headers="orderHeaders"
          :tableData="orderList"
          :hasOperateColumn="false">
        </table-panel>
      </col2-block>
    </form-panel>
  </div>
</template>
<script>
import { Col2Block, Col2Item, TablePanel } from '@/components/bussiness'
import { getEstatesOrderFullURL } from './api'
import { moneyTypeMap } from './map'
export default {
  components: {
    Col2Block,
    Col2Item,
    TablePanel
  },
  data () {
    return {
      moneyTypeMap,
      queryUrl: getEstatesOrderFullURL,
      orderId: this.$route.query.id,
      orderInfo: {},
      orderHeaders: [
        {
          prop: 'item',
          label: '收费项目'
        },
        {
          prop: 'billDate',
          label: '出账月份'
        },
        {
          prop: 'billTime',
          label: '计费周期'
        },
        {
          prop: 'totalPrice',
          label: '账单金额'
        },
        {
          prop: 'payStatusName',
          label: '缴费状态'
        },
        {
          prop: 'paidMoney',
          label: '其它途径已缴金额'
        },
        {
          prop: 'unpaidMoney',
          label: '应缴金额'
        },
        {
          prop: 'priceName',
          label: '单位价格'
        },
        {
          prop: 'unit',
          label: '计价单位'
        },
        {
          prop: 'count',
          label: '面积/用量/数量'
        }
      ],
      orderList: []
    }
  },
  mounted () {
    this.orderId && this.$refs.form.getData({ id: this.orderId, estatesOrderId: this.orderId })
  },
  methods: {
    update (data) {
      this.orderInfo = data
      this.orderList = data.estatesPaymentList.map((item, index) => {
        return {
          item: item.item,
          billDate: item.billYear ? `${item.billYear}年${item.billMonth}年` : '-',
          billTime: `${item.billStartTime}-${item.billEndTime}`,
          totalPrice: `￥${item.totalPrice}`,
          payStatusName: index === 0 ? '-' : item.payStatus ? '已缴费' : '未缴费',
          paidMoney: `￥${item.paidMoney}`,
          unpaidMoney: `￥${item.unpaidMoney}`,
          priceName: item.price ? `￥${item.price}` : '-',
          unit: item.unit,
          count: item.count
        }
      })
    }
  }
}
</script>
